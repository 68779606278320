<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="realtorid">
         Custom Style & Script
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="success"
          @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!realtorid"
          title="Realtor Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="realtorid"
          title="Realtor Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>
 <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div>
        <el-alert
          title="Please input your code without any sepical characters, style & script Tags"
          type="warning" :closable="false"
        /><br>
        </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition"
  >
    <div class="panel panel-default">
      <div
        class="panel-title"
        style="padding-bottom: 25px;margin-bottom: 20px;"
      >
        <i class="fa fa-bars" /> Custom Style & Script
      </div>
        <el-row :gutter="20">
            <el-col :="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-form-item label="Custom Stylesheet Code">
                <el-input
                  v-model="data.config_admin_style"
                  type="textarea"
                  rows="12"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :="24" :sm="24" :md="24" :lg="12" :xl="12">
                <el-form-item label="Custom Script Code">
                <el-input
                  v-model="data.config_admin_script"
                  type="textarea"
                  rows="12"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
        </el-row>
        
    </div>
  </el-form>
</template>
<script>
import UserService from "@/api/user.service";
import SettingService from "@/api/setting.service";
  export default {
    data() {
         var checkMlsid = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the MLS®/Board user ID.'));
        } else {
          callback();
        }
      };      
      return {
        showAlertAdded: false,
        labelPosition: 'top',
        domainsid: null,
        data: {
          config_admin_style: null,
          config_admin_script:null
        },
        rules: {
          config_admin_style: [
            { validator: checkMlsid, trigger: 'blur' }
          ],
        }
      };
    },     
	mounted() {
      this.getdomains();
      
  },
   methods: {
       submitForm(formName) {
        this.$refs[formName].validate((valid) => {
         if (valid && this.domainsid) {
            return SettingService.updateCustomstyle(this.domainsid, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
	getdomains(){
        return UserService.getUserBoard().then(response => {
            this.domainsid =response.data.domains[0].id;
            this.getsetting();
        });
        },
        getsetting(){
        return SettingService.get(this.domainsid).then(response => {
        this.data = response.data;        
      });
	},
    }
  }
</script>
<style lang="scss" scoped>
 .panel-title{
      border-bottom: 1px solid #9a83835c;
      padding-bottom: 10px;
      margin-bottom: 10px; 
      text-align: left;
  }

.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
</style>